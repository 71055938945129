/* eslint-disable no-confusing-arrow */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import CardAddress from '../card-address'
import IconMinius from '../../../../assets/icons/seasongroup_icons_minimize.svg'
import IconPlus from '../../../../assets/icons/seasongroup_icons_plus.svg'
import './list-countries.scss'

const sections = (accordionItems, click, t) => _.map(accordionItems, (i, idx) => {
  const arrowClass = classNames({
    accordionAddress__arrow: true,
    accordionAddress__arrow__active: i.open,
  })

  const blockContentClass = classNames({
    accordionAddress__content: true,
    accordionAddress__content__active: i.open,
  })

  const titleContentClass = classNames({
    accordionAddress__section__title: true,
    accordionAddress__section__title__active: i.open,
  })

  if (accordionItems.length === 0) return null
  return (
    <div className="accordionAddress__container" key={i.title}>
      <div className="accordionAddress__section">
        <p onClick={() => click(accordionItems.indexOf(i))} className={titleContentClass}>
          {i.title}
        </p>
        {

          <div className={arrowClass}
            onClick={() => click(accordionItems.indexOf(i))}
          >
            {!i.open ? <IconPlus width={20} fill={'#1B1E60'}/> : <IconMinius width={20} fill={'#018AFF'} /> }
          </div>

        }
      </div>
      <div className={blockContentClass}>
        <CardAddress info={i.item} t={t}/>
      </div>
    </div>
  )
})

const ListCountries = ({
  data, t, onHandleSelected,
}) => {
  const url = typeof window !== 'undefined' ? window.location.href : ''
  const [accordionItems, setAccordionItems] = useState([])

  useEffect(() => {
    const accordion = []
    data.forEach((i) => {
      accordion.push({
        title: i.title,
        open: i.is_headquarter,
        item: i,
      })
    })
    setAccordionItems(accordion)
  }, [data, url])

  function click(i) {
    // const newAccordion = accordionItems.slice()
    if (_.isFunction(onHandleSelected)) {
      onHandleSelected(accordionItems[i].item)
    }
    if (accordionItems[i].open) {
      const newAccordion = accordionItems.map((accordionItem) => ({
        ...accordionItem,
        open: false,
      }))
      setAccordionItems(newAccordion)
    } else {
      const newAccordion = accordionItems.map((accordionItem) => ({
        ...accordionItem,
        open: !!accordionItem[i],
      }))
      newAccordion[i].open = !newAccordion[i].open
      setAccordionItems(newAccordion)
    }
  }

  return <div className="accordionAddress">{sections(accordionItems, click, t)}</div>
}

export default ListCountries
