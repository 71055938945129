import _ from 'lodash'
import React from 'react'
import './card-address-style.scss'
import IconLocation from '../../../../assets/icons/seasongroup_icons_location.svg'
import IconPhone from '../../../../assets/icons/seasongroup_icons_phone.svg'
import IconEmail from '../../../../assets/icons/seasongroup_icons_email.svg'

const CardAddress = ({ info, t }) => {
  const address = _.get(info, 'address').replaceAll('\r\n', ' <br/>')

  return (
    <div className="cardAddress__container">
      <div className="cardAddress__body">
        <table>
          <tbody>
            <tr>
              <td><IconLocation width={24} /></td>
              <td>
                <div dangerouslySetInnerHTML={{ __html: address }} />
              </td>
            </tr>
            <tr>
              <td><IconPhone width={24} /></td>
              <td><a href={`tel:${_.get(info, 'telephone')}`}>{_.get(info, 'telephone')}</a></td>
            </tr>
            <tr>
              <td><IconEmail width={24} /></td>
              <td><a href={`mailto:${_.get(info, 'email')}`}>{_.get(info, 'email')}</a></td>
            </tr>
          </tbody>
        </table>
        <div className="rich-text">
          <div dangerouslySetInnerHTML={{ __html: _.get(info, 'copy')}} />
        </div>
      </div>
    </div>
  )
}

export default CardAddress
