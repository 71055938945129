import _ from 'lodash'
import React, { useRef, useEffect, useState } from 'react'
import {
  GoogleMap,
  LoadScript,
  Circle,
} from '@react-google-maps/api'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import demoFancyMapStyles from './map-style.json'
import getCustomRadiusForZoom from './customRadioCircle'

const MapWithAMarkerClusterer = ({
  selectedOffice,
}) => {
  const breakpoints = useBreakpoint()
  const [zoom, setZoom] = useState(9)
  const [radius, setRadius] = useState(300)
  const circleRef = useRef(null)
  const mapRef = useRef(null)

  const options = {
    strokeColor: '#018AFF',
    strokeOpacity: 0.25,
    strokeWeight: 20,
    fillColor: '#018AFF',
    fillOpacity: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    // radius: 3000,
    zIndex: 1,
  }

  const getCenter = (office) => {
    if (!_.isEmpty(office)) {
      return {
        lat: office.lat,
        lng: office.lng,
      }
    }
    return {
      lat: 22.3644949,
      lng: 114.1189459,
    }
  }

  function onZoomChanged() {
    if (circleRef && circleRef.current) {
      setZoom(mapRef.current.state.map.zoom)
    }
  }

  useEffect(() => {
    const result = getCustomRadiusForZoom(zoom)
    setRadius(result)
  }, [zoom])

  return (
    <LoadScript
      googleMapsApiKey={process.env.GATSBY_GOOGLEMAP_ID}
    >
      <GoogleMap
        // zoom={getZoom(_.get(selectedOffice, 'country'))}
        ref={mapRef}
        zoom={9}
        onZoomChanged={() => onZoomChanged()}
        mapContainerStyle={{
          height: !breakpoints.xs ? '70vh' : '286px',
          maxHeight: !breakpoints.xs ? '600px' : '286px',
        }}
        options={{
          styles: demoFancyMapStyles,
          disableDefaultUI: true,
          zoomControl: true,
          keyboardShortcuts: false,
          draggable: true,
        }}
        center={getCenter(selectedOffice)}
      >
        <Circle
          ref={circleRef}
          center={getCenter(selectedOffice)}
          // required
          options={{
            ...options,
            radius,
          }}
        />

      </GoogleMap>

    </LoadScript>

  )
}

export default MapWithAMarkerClusterer
