import _ from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useScrollTo } from 'react-use-window-scroll'
import useLocation from '../../../../hooks/use-location'
import './location.scss'
import Map from '../../../../components/map'
import StaticMap from '../../../../assets/images/seasongroup_aboutus_map.jpeg'
import ListCountries from '../list-contries'

const Location = ({
  data,
  idx,
  hash,
}) => {
  const [selectedOffice, setSelectedOffice] = useState({})
  const { t } = useTranslation()
  const scrollTo = useScrollTo()
  const locationIP = useLocation()
  const isChina = locationIP === 'CN'
  const containerExperienceRef = useRef(null)
  const onHandleSelected = (selected) => {
    setSelectedOffice(_.get(selected, 'map_location'))
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (hash.length && containerExperienceRef && containerExperienceRef.current) {
        scrollTo({ top: containerExperienceRef.current.offsetTop - 100, left: 0, behavior: 'smooth' })
      }
    }, 300)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    setSelectedOffice(_.get(data, '[0].map_location'))
  }, [])

  return (

    <div className="location__container" key={idx} ref={containerExperienceRef}>
      <div className="aboutUs__title">
        <h3>{_.get(data, 'title')}</h3>
      </div>
      <div className="location__map__contianner row">
        <div className="location__map col-12 order-md-2 col-md-6">
          {
            isChina ? (
              <div className="location__map__image">
                <img src={StaticMap} alt="static map"/>
              </div>
            ) : (
              <Map
                selectedOffice={selectedOffice}
              />

            )
          }
        </div>
        <div className="location__list col-12 order-md-1 col-md-6">
          <ListCountries
            data={_.get(data, 'list', [])}
            t={t}
            onHandleSelected={onHandleSelected}
          />
        </div>

      </div>

    </div>
  )
}

export default Location
