import React, { useEffect, useState } from 'react'
import makeRequest from '../../helpers/make-request'
import AboutUsView from './about-us-view'
import { useTranslation } from 'react-i18next'

const AboutUsCotroller = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [dataAboutUs, setDataAboutUs] = useState([])
  const controller = new AbortController()
  const { i18n } = useTranslation();

  // catch with useEffect so the data will be contained
  const getDataAboutUs = async () => {
    setLoading(true)
    const fields = 'id,title,slug,acf'
    const headers = {
      'Content-Type': 'application/json',
    }

    // fetch the data with makerequest
    makeRequest({
      headers,
      endPoint: 'pages',
      signal: controller.signal,
      params: {
        slug: 'about',
        fields,
        lang: i18n.language
      },
    }).then((resp) => {
      setDataAboutUs(resp.data[0])
      setLoading(false)
    })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }
  useEffect(() => {
    getDataAboutUs()
    return () => {
      controller.abort()
    }
  }, [])

  const viewProps = {
    data: dataAboutUs,
    loading,
    location,
  }
  return (
    <AboutUsView {...viewProps}/>
  )
}

export default AboutUsCotroller
