import React from 'react'
import './values.scss'
import {
  Icon,
  Tagline,
} from '../../../../components'

const Values = ({
  data,
  idx,
  t,
}) => (
  <div className="values__container" key={idx}>
    <div className='values__box'>
      <div className="row ">
        <div className="col-12 order-md-2 col-md-4">
          <div className="values__image values__image__shadow">
            <img src={data.image.url} alt={data.image.alt} />
          </div>
        </div>
        <div className="col-12 order-md-1 col-md-8">
          <div className="row">
            <div className="col-12">
              <Tagline text={t('about.values.tag')} />
            </div>
            <div className="col-md-5 col-12">
              <div className="values__titles">
                <span className="values__icon"><Icon name="icon1" height="32px"/> </span>
                <span>{t('about.values.king')}</span>
              </div>
              <div className="values__titles">
                <span className="values__icon"><Icon height="32px" name="icon2"/> </span>
                <span>{t('about.values.open')}</span>
              </div>
              <div className="values__titles">
                <span className="values__icon"><Icon height="32px" name="icon3"/> </span>
                <span>{t('about.values.cool')}</span>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="values__titles">
                <span className="values__icon"><Icon height="32px" name="icon4"/> </span>
                <span>{t('about.values.innovative')}</span>
              </div>
              <div className="values__titles">
                <span className="values__icon"><Icon height="32px" name="icon5"/> </span>
                <span>{t('about.values.rights')}</span>
              </div>
              <div className="values__titles">
                <span className="values__icon"><Icon height="32px" name="icon6"/> </span>
                <span>{t('about.values.fun')}</span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
)

export default Values
